import {
SearchIcon,
MenuIcon,
EyeIcon,
LockClosedIcon,
DocumentTextIcon,
XIcon
} from '@heroicons/react/solid'
import Link from 'next/link';
import Langs from 'components/Langs';
import User from 'components/User';


const menuIcons = [
  <EyeIcon key="eye" aria-hidden="true" focusable="false" className="inline lg:w-[22px] lg:h-[22px] lg:mb-[0] w-[16px] h-[16px] mb-[5px]"/>,
  <LockClosedIcon key="lock" aria-hidden="true" focusable="false" className="inline lg:w-[22px] lg:h-[22px] lg:mb-[0] w-[16px] h-[16px] mb-[5px]"/>,
  <DocumentTextIcon key="document" aria-hidden="true" focusable="false" className="inline lg:w-[22px] lg:h-[22px] lg:mb-[0] w-[16px] h-[16px] mb-[5px]"/>,
]
function Header({ locale, menuLabels, menuStatus, setMenuStatus}) {
  const linkHome = locale === 'it' ? '/' : "/en"
  return (
    <div className="fixed z-10 bg-white top-[0] left-[0] right-[0] flex justify-between items-center px-[20px] lg:h-[65px] h-[50px] border-b-[1px] border-grayL">
      <Link href={linkHome}>
        <a title="Cantiere Creativo Guidelines" className="logo"></a>
      </Link>
      <nav aria-label="Main menu">
        <ul className="main-nav flex justify-around lg:relative fixed left-0 right-0 bottom-0 lg:bg-white bg-primary lg:text-black text-white lg:border-t-[0] border-t-[1px] border-grayL">
          <li className="lg:mx-[25px] uppercase lg:text-[14px] text-[9px] grow p-[10px]">
            <Link href="/search">
              <a className="lg:flex text-center">
                <div className="lg:mr-[5px] block lg:text-primary"><SearchIcon className="inline lg:w-[22px] lg:h-[22px] lg:mb-[0] w-[16px] h-[16px] mb-[5px]" /></div>
                <div>{locale == 'it' ? "Cerca" : "Search"}</div>
              </a>
            </Link>
          </li>
          {menuLabels && menuLabels.map((item,i)=>{
            const flexOrder = i < 1 ? "first" : "last"
            return(
              // <div key={item.menuLabel} className={`order-${flexOrder} lg:mx-[25px] uppercase lg:text-[14px] text-[9px] grow p-[10px] lg:border-[0] border-l border-grayL`}>
              <li key={item.menuLabel} className={`lg:mx-[25px] uppercase lg:text-[14px] text-[9px] grow p-[10px] lg:border-[0] border-l border-grayL`}>
                <Link href={`/${item.slug}`}>
                  <a className="lg:flex text-center">
                    <div className="lg:mr-[5px] block lg:text-primary">{menuIcons[i]}</div>
                    <div>{item.menuLabel}</div>
                  </a>
                </Link>
              </li>
            )
          })}

          <div key="toggle" className="lg:mx-[25px] uppercase lg:text-[14px] text-[9px] grow p-[10px] lg:border-[0] border-l border-grayL lg:hidden">
            {/* <Link href=""> */}
              <a className="lg:flex text-center" onClick={() => setMenuStatus(!menuStatus)}>
                <div className="lg:mr-[5px] block lg:text-primary">
                  {!menuStatus &&
                    <MenuIcon key="menu" className="inline lg:w-[22px] lg:h-[22px] lg:mb-[0] w-[16px] h-[16px] mb-[5px]" />
                  }
                  {menuStatus &&
                    <XIcon key="close" className="inline lg:w-[22px] lg:h-[22px] lg:mb-[0] w-[16px] h-[16px] mb-[5px]" />
                  }
                </div>
                <div>Menu</div>
              </a>
            {/* </Link> */}
          </div>

        </ul>
      </nav>
      <div className="flex items-center">
      <div className="mr-5">
        <User />
      </div>
        <Langs />
      </div>
    </div>
  );
}
export default Header;
