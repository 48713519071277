import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react';
import { UserCircleIcon } from '@heroicons/react/solid'
import { getProviders, useSession, signIn, signOut } from "next-auth/react"


const User = (providers) => {
  const { data: session } = useSession()
  const router = useRouter();
  const { locale } = router;
  const greetingsLabel = locale === 'it' ? 'Ciao' : 'Hi';
  const signInLabel = locale === 'it' ? 'Accedi' : 'Sign in';
  const signOutLabel = locale === 'it' ? 'Esci' : 'Sign out';

  const [toggleDropdown, setToggleDropdown] = useState(false)

  return (
    <div className="relative">
      {session &&
        <button
          onClick={()=>setToggleDropdown(!toggleDropdown)}
          className="inline-block lg:w-[35px] lg:h-[35px] lg:mb-[0] w-[25px] h-[25px] mb-[5px] overflow-hidden rounded-full"
        >
          <img
            src={session.user.image}
            alt={session.user.name}
            title={session.user.name}
          />
        </button>
      }
      {!session &&
        <button
          onClick={() => setToggleDropdown(!toggleDropdown)}
        >
          <UserCircleIcon className="inline lg:w-[35px] lg:h-[35px] lg:mb-[0] w-[25px] h-[25px] mb-[5px]" />
        </button>
      }

      {toggleDropdown &&
        <div className="absolute top-[110%] left-[50%] translate-x-[-50%] bg-white shadow-lg px-5 py-3 border-grayL border-[1px] rounded-[8px]">
          {session &&
            <div>
              <p className="whitespace-nowrap mb-3">
                {greetingsLabel} {session.user.name.split(' ')[0]}
              </p>
              <a
                href="#"
                className="whitespace-nowrap font-bold text-primary hover:text-secondary"
                onClick={() => signOut()}
              >
                {signOutLabel}
              </a>
            </div>
          }
          {!session &&
            <div>
              <a
                href="#"
                className="whitespace-nowrap font-bold text-primary hover:text-secondary"
                onClick={() => signIn('google')}
              >
                {signInLabel}
              </a>
            </div>
          }
        </div>
      }
    </div>
  );
};


export default User;
