import Link from 'next/link';
import { useRouter } from 'next/router';

const Nav = ({ pages, page, prefix = "", menuStatus, setMenuStatus }) => {
  const router = useRouter();
  const { locale } = router;
  const thisPage = page?.slug
  const urlPrefix = prefix ? `/${prefix}` : ""

  function addPrimaryClass(item) {
    if (item == thisPage) {
      return "text-secondary"
    }
    else{
      return "text-white"
    }
  }
  return (
    <nav>
      <ul>
        {pages?.filter(p=> p.parent == null).map((page) => {
          return (
            <li key={page.id} className="flex justify-between border-b border-white border-opacity-30">
              <Link href={`${urlPrefix}/${page.slug}`}>
                <a
                  title={page.menuLabel}
                  onClick={() => setMenuStatus(false)}
                  className={`${addPrimaryClass(page.slug)} px-[20px] py-[15px] uppercase font-bold`}
                >
                  {page.menuLabel}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
export default Nav;
