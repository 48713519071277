import Head from "next/head";
import { renderMetaTags } from "react-datocms";

const Seo = ({ tags=[]}) => {
  return (
    <Head>
      {renderMetaTags(tags)}
    </Head>
  );
};

export default Seo;
