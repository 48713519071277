import Link from 'next/link';

export default function Example() {
  const startYear = 2010
  const currentYear = new Date().getFullYear()

  const year = startYear < currentYear
    ? `${startYear}-${currentYear}`
    : currentYear

  return (
    <footer className="mt-[50px] mb-[25px] text-xs md:text-sm">
      <span className='mr-[4px]'>®{year}</span>
        <Link
          href="https://www.cantierecreativo.net"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Link al sito web di Cantiere Creativo (apre in una nuova pagina)">
          <a className='mr-[4px] underline font-bold'>
            Cantiere Creativo
          </a>
        </Link>
        <span className='hidden md:blck'> - </span>
        <div className='md:hidden h-2'></div>
        <span className='mr-[4px]'>Made with</span>
        <Link
          href="https://nextjs.org"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Link al sito web di Nextjs (apre in una nuova pagina)">
          <a className='mr-[4px] underline font-bold'>
            NextJs
          </a>
        </Link>
        <span className='mr-[4px]'>and</span>
        <Link
          href="https://www.datocms.com"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Link al sito web di DatoCMS (apre in una nuova pagina)">
          <a className='mr-[4px] underline font-bold'>
            DatoCMS
          </a>
        </Link>



    </footer>
  );
}
