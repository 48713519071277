import Link from 'next/link';
import { useRouter } from 'next/router';
import { ChevronRightIcon } from '@heroicons/react/solid'

const Langs = () => {
  const router = useRouter();
  const { locale } = router;
  return (
    <nav aria-label="Language switcher">
      <ul>
        {router?.locales
          ?.filter((l) => l != locale)
          .map((l) => {
            return (
              <li key={l} className="px-2 uppercase w-[60px]">
                <Link href="/" locale={l}>
                  <a className="flex justify-between">{l} <ChevronRightIcon className="w-[25px] text-primary" /></a>
                </Link>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default Langs;
