import Link from 'next/link';
import { useRouter } from 'next/router';

const Breadcrumbs = (thisPage) => {
  const { page, prefix, menuLabels } = thisPage
  const router = useRouter()
  const path = router.asPath
  const parentSplit = path.split('/')
  const parentPath = parentSplit[1]
  const thisPath = parentSplit[parentSplit.length - 1]


  const match = menuLabels
    ? menuLabels?.filter(l => l.slug == parentPath ).map(label =>{ return label})
    : "";

  const root = match.length > 0 ? `/${match[0]?.slug}` : ''

  const renderPrefix = () => {
    return (
      <>
        {match.length > 0  && match[0]?.slug != page.slug &&
        <li>
          <span className="mx-[5px]">/</span>
            <Link href={`/${match[0]?.slug}`}>
            <a className="hover:underline">{match[0]?.menuLabel}</a>
          </Link>
        </li>
      }
      </>
    );
  };

  return (
    <ul className="flex flex-wrap items-start text-[14px] font-bold mb-[50px]">
      <li className="whitespace-nowrap">
        <Link href="/">
          <a className="hover:underline">Home</a>
        </Link>
      </li>

      {menuLabels && parentPath != thisPath &&
        renderPrefix()
      }

      {page?.parent?.parent?.slug &&
        <li className="whitespace-nowrap">
          <span className="mx-[5px]">/</span>
          <Link href={`${root}/${page.parent.parent.slug}`}>
            <a className="hover:underline">{page?.parent.parent.title}</a>
          </Link>
        </li>
      }
      {page?.parent?.slug && page?.parent?.parent?.slug &&
        <li className="whitespace-nowrap">
          <span className="mx-[5px]">/</span>
          <Link href={`${root}/${page.parent.parent.slug}/${page.parent.slug}`}>
            <a className="hover:underline">{page?.parent.title}</a>
          </Link>
        </li>
      }
      {page?.parent?.slug && !page?.parent?.parent?.slug &&
        <li className="whitespace-nowrap">
          <span className="mx-[5px]">/</span>
          <Link href={`${root}/${page.parent.slug}`}>
            <a className="hover:underline">{page?.parent.title}</a>
          </Link>
        </li>
      }
      <li className="whitespace-nowrap">
        <span className="mx-[5px]">/</span>
        {page?.menuLabel ? page?.menuLabel : page?.title}
      </li>
    </ul>
  );
};

export default Breadcrumbs;
