import React, { useState } from 'react';
import Link from 'next/link';
import { ChevronLeftIcon } from '@heroicons/react/solid'


import Header from 'components/Header';
import Seo from 'components/Seo';
import Nav from 'components/Nav';
import NavGlossary from 'components/NavGlossary';
import Breadcrumbs from 'components/Breadcrumbs';
import Footer from 'components/Footer';


function Layout({ children, pages, page, prefix, menuLabels, locale }) {
  const glossaryNav = prefix == "glossario" || prefix == "glossary" ? true : false;

  const [menuStatus, setMenuStatus] = useState(false)
  const openMenuClass = menuStatus ? "opacity-1 visible" : "opacity-0 invisible"
  const linkHome = locale === 'it' ? '/' : "/en"
  const { seo } = page
  return (
    <>
      <nav className="relative z-[11]"
        aria-label={locale === 'it' ? "Menù skiplinks" : "Skiplinks"}
      >
        <a
          href="#content"
          title={locale === 'it' ? "Vai al contenuto principale" : "Skip to main content"}
          className="skip-link"
        >
          {locale === 'it' ? "Vai al contenuto principale" : "Skip to main content"}
        </a>
        <a
          href="#footer"
          title={locale === 'it' ? "Vai al footer" : "Jump to footer"}
          className="skip-link"
        >
          {locale === 'it' ? "Vai al footer" : "Jump to footer"}
        </a>
      </nav>
      <Header locale={locale} menuLabels={menuLabels ? menuLabels : {}} menuStatus={menuStatus} setMenuStatus={setMenuStatus}/>
      <Seo tags={seo} switchPage={page} />

      <div className={`fixed lg:top-[65px] top-[50px] lg:bottom-[0] bottom-[55px] lg:w-[320px] w-[100%] overflow-auto lg:opacity-100 lg:visible transition-all z-[1] bg-primary ${openMenuClass}`}>
        {glossaryNav &&
          <NavGlossary
            pages={pages}
            page={page}
            prefix={prefix}
            menuStatus={menuStatus}
            setMenuStatus={setMenuStatus}
          />
        }
        {!glossaryNav &&
          <Nav
            pages={pages}
            page={page}
            menuLabels={menuLabels}
            prefix={prefix}
            menuStatus={menuStatus}
            setMenuStatus={setMenuStatus}
          />
        }

        {!pages &&
          <div className="lg:hidden">
            <Link href={linkHome}>
              <a className="px-3 py-4 text-white flex items center uppercase font-bold border-b border-white">
                <ChevronLeftIcon className="w-6 h-6" />
                <span>{locale == 'it' ? "Torna alla home" : "Back home"}</span>
              </a>
            </Link>
          </div>
        }
      </div>
      <div className="h-full pt-[70px] lg:ml-[320px] flex flex-col">
        <main id="content" className="container mx-[auto] my-[20px] px-[20px]">
          {page.title && menuLabels &&
            <Breadcrumbs page={page} prefix={prefix} menuLabels={menuLabels}/>
          }
          {children}
        </main>
        <div id="footer" className="container md:mx-[auto] px-[20px] mt-auto pb-[50px] md:pb-0 ">
          <Footer/>
        </div>
      </div>
    </>
  );
};


export default Layout;
