import Link from 'next/link';
import { useRouter } from 'next/router';
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'


const Nav = ({ pages, page, menuLabels, menuStatus,setMenuStatus }) => {
  const router = useRouter();
  const { locale } = router;
  const thisPage = page?.slug
  const firstParent = page?.parent?.slug
  const secondParent = page?.parent?.parent?.slug

  const path = router.asPath
  const parentPath = path.split('/')[1]

  const match = menuLabels ? menuLabels?.filter(l => l.slug == parentPath).map(label => {
    return label
  }) : "";

  const root = match && match.length > 0 &&  match[0]?.slug != '' ? `/${match[0]?.slug}/` : '/'

  function addPrimaryClass(item) {
    if (item == thisPage || item == firstParent || item == secondParent) {
      return "bg-white text-primary rounded-lg"
    }
    else{
      return "text-white"
    }
  }

  return (
    <nav>
      <ul>
        {pages?.filter(p=> p.parent == null).map((page) => {
          return (
            <Disclosure key={page.id} >
              {({ open }) => (
                <li className="overflow-hidden">
                  <div className="flex justify-between border-b border-white border-opacity-30">
                    <Link href={`${root}${page.slug}`}>
                      <a
                        title={page.menuLabel}
                        onClick={()=>setMenuStatus(false)}
                        className={`${addPrimaryClass(page.slug)} mx-[15px] my-[15px] px-[8px] py-[1px] uppercase font-bold`}
                      >
                        {page.menuLabel}
                      </a>
                    </Link>
                    {page?.children?.length >= 1 &&
                      <Disclosure.Button className="px-6">
                        <ChevronDownIcon
                          aria-hidden="true"
                          focusable="false"
                          className={`${open ? "transform rotate-180" : ""} w-7 h-7 text-white`}
                        />
                        <span className="sr-only">{locale === 'it' ? "Apri/chiudi il sottomenu" :"Toggle submenu"}</span>
                      </Disclosure.Button>
                    }
                  </div>

                  {page?.children?.length >= 1 &&
                    <Disclosure.Panel className="">
                      {page.children.filter(c => c.slug !== null).map((secondLevel) => {
                        const match = secondLevel.slug == thisPage
                        return (
                          <Disclosure key={secondLevel.id} >
                            {({ open2 }) => (
                            <>
                                <div className="flex justify-between pl-[30px] py-[15px] border-b border-white border-opacity-30">
                                  <Link href={`${root}${page.slug}/${secondLevel.slug}`}>
                                    <a
                                      title={secondLevel.menuLabel}
                                      onClick={() => setMenuStatus(false)}
                                      className={`${addPrimaryClass(secondLevel.slug)} px-[8px] py-[1px] font-bold`}
                                    >
                                      {secondLevel.menuLabel}
                                    </a>
                                </Link>
                                {secondLevel.children.length >= 1 &&
                                  <Disclosure.Button className="px-6">
                                    <ChevronDownIcon
                                      className={`${open2 ? "transform rotate-180" : ""} w-7 h-7 text-white`}
                                    />
                                    <span className="sr-only">{locale === 'it' ? "Apri/chiudi il sottomenu" : "Toggle submenu"}</span>
                                  </Disclosure.Button>
                                }
                              </div>
                              <Disclosure.Panel>
                                  {secondLevel.children.filter(c => c.slug !== null).map((thirdLevel) => {
                                    return(
                                      <div key={thirdLevel.id} className="flex pl-[40px] py-[15px] border-b border-white border-opacity-30">
                                        <Link href={`${root}${page.slug}/${secondLevel.slug}/${thirdLevel.slug}`}>
                                          <a
                                            title={thirdLevel.menuLabel}
                                            onClick={() => setMenuStatus(false)}
                                            className={`${addPrimaryClass(thirdLevel.slug)} px-[8px] py-[1px] font-bold`}
                                          >
                                            {thirdLevel.menuLabel}
                                          </a>
                                        </Link>

                                      </div>
                                    )
                                  })
                                }
                              </Disclosure.Panel>
                            </>
                          )}
                          </Disclosure>
                        );
                      })}
                    </Disclosure.Panel>

                  }
                </li>
              )}
            </Disclosure>
          );
        })}
      </ul>
    </nav>
  );
};
export default Nav;
